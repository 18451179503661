exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-jsx": () => import("./../../../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-aufbewahrung-jsx": () => import("./../../../src/pages/aufbewahrung.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-jsx" */),
  "component---src-pages-baenke-jsx": () => import("./../../../src/pages/baenke.jsx" /* webpackChunkName: "component---src-pages-baenke-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-einrichtungsplanung-jsx": () => import("./../../../src/pages/einrichtungsplanung.jsx" /* webpackChunkName: "component---src-pages-einrichtungsplanung-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-leuchten-jsx": () => import("./../../../src/pages/leuchten.jsx" /* webpackChunkName: "component---src-pages-leuchten-jsx" */),
  "component---src-pages-sessel-jsx": () => import("./../../../src/pages/sessel.jsx" /* webpackChunkName: "component---src-pages-sessel-jsx" */),
  "component---src-pages-sofas-2-4-sitzer-sofas-jsx": () => import("./../../../src/pages/sofas/2-4-sitzer-sofas.jsx" /* webpackChunkName: "component---src-pages-sofas-2-4-sitzer-sofas-jsx" */),
  "component---src-pages-sofas-ecksofas-jsx": () => import("./../../../src/pages/sofas/ecksofas.jsx" /* webpackChunkName: "component---src-pages-sofas-ecksofas-jsx" */),
  "component---src-pages-sofas-jsx": () => import("./../../../src/pages/sofas.jsx" /* webpackChunkName: "component---src-pages-sofas-jsx" */),
  "component---src-pages-sofas-schlafsofas-jsx": () => import("./../../../src/pages/sofas/schlafsofas.jsx" /* webpackChunkName: "component---src-pages-sofas-schlafsofas-jsx" */),
  "component---src-pages-stuehle-jsx": () => import("./../../../src/pages/stuehle.jsx" /* webpackChunkName: "component---src-pages-stuehle-jsx" */),
  "component---src-pages-tische-jsx": () => import("./../../../src/pages/tische.jsx" /* webpackChunkName: "component---src-pages-tische-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../../../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */),
  "component---src-templates-bank-jsx": () => import("./../../../src/templates/Bank.jsx" /* webpackChunkName: "component---src-templates-bank-jsx" */),
  "component---src-templates-sessel-jsx": () => import("./../../../src/templates/Sessel.jsx" /* webpackChunkName: "component---src-templates-sessel-jsx" */),
  "component---src-templates-sofa-jsx": () => import("./../../../src/templates/Sofa.jsx" /* webpackChunkName: "component---src-templates-sofa-jsx" */),
  "component---src-templates-stuhl-jsx": () => import("./../../../src/templates/Stuhl.jsx" /* webpackChunkName: "component---src-templates-stuhl-jsx" */),
  "component---src-templates-tisch-jsx": () => import("./../../../src/templates/Tisch.jsx" /* webpackChunkName: "component---src-templates-tisch-jsx" */)
}

